import { CSSProperties, FC, ReactNode } from 'react';
import styles from './skeleton-block.module.scss';

interface SkeletonBlockProps {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
}
export const SkeletonBlock: FC<SkeletonBlockProps> = ({ children, style = {}, className }) => {
  return (
    <div style={style} className={`${styles.skeletonBlock} ${className}`}>
      {children}
    </div>
  );
};
