import { FC } from 'react';
import { Button, Flex, Modal, Typography } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ProfileDropdown } from '../components/profile-dropdown/profile-dropdown.component';
import { useAppStore } from '@/stores';
import styles from './header.module.scss';
import { ChangeLanguage, CompaniesIcon } from '@/components';
import DownloadPWA from '../components/download-pwa';
import { useIsAdmin, useModal } from '@/hooks';
import { CompanySelect } from '../components/company-select/company-search.component';

const { Title } = Typography;

export const MobileHeader: FC = () => {
  const { setIsMobileDrawerOpen } = useAppStore();
  const isAdmin = useIsAdmin();
  const { t } = useTranslation();
  const { isOpen, openModal, closeModal } = useModal();

  const handleCompanySelect = () => {
    closeModal();
  };

  return (
    <nav className={styles.mobilenav}>
      <Flex justify="space-between" align="center">
        <Flex gap={10} align="center">
          <Button
            size="middle"
            className={styles.settingsBtn}
            type="link"
            onClick={() => setIsMobileDrawerOpen(true)}
            icon={<MenuOutlined />}
          />
          {isAdmin && (
            <Button
              size="middle"
              className={styles.settingsBtn}
              type="link"
              onClick={openModal}
              icon={<CompaniesIcon />}
            />
          )}
        </Flex>

        <Flex align="center" gap={8}>
          {/* <NotificationsDropdown /> */}
          <DownloadPWA />
          <ChangeLanguage />
          <ProfileDropdown isMobile />
        </Flex>
      </Flex>
      {isOpen && (
        <Modal
          className={styles.dynamicModal}
          centered
          title={
            <Flex align="center" gap="8px">
              <Title level={3}>{t('selectCompany')}</Title>
            </Flex>
          }
          onCancel={closeModal}
          footer={null}
          open={isOpen}
        >
          <div className={styles.formWrapper}>
            {isAdmin && <CompanySelect handleCompanySelect={handleCompanySelect} />}
          </div>
        </Modal>
      )}
    </nav>
  );
};
