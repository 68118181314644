import { SVGProps } from 'react';

export const ExternalDistributorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="66" height="84" fill="none" viewBox="0 0 66 84" {...props}>
    <path
      fill="#000"
      d="M15.097 35.663c-1.29.014-2.364-.686-2.354-1.286-.032-1.89-2.841-1.886-2.873 0 0 1.933 1.577 3.499 3.772 3.987-.334 2.662 3.204 2.673 2.874 0 5.82-1.368 4.483-8.169-1.437-8.147-3.097-.137-3.04-2.464.018-2.572 1.304.007 2.317.693 2.317 1.286.032 1.89 2.841 1.886 2.874 0 0-1.93-1.578-3.5-3.773-3.988.335-2.665-3.204-2.672-2.873 0-5.82 1.369-4.487 8.17 1.437 8.148 3.042.118 3.067 2.442.018 2.572"
    />
    <path
      fill="#000"
      d="M65.236 2.542C65.68 1.378 64.29.157 63.185.836L60.738 2.2 58.292.836a1.43 1.43 0 0 0-1.401 0L54.45 2.2 52.02.839a1.44 1.44 0 0 0-1.405 0l-2.436 1.362L45.747.839a1.44 1.44 0 0 0-1.404 0l-2.429 1.362L39.482.839a1.44 1.44 0 0 0-1.404 0l-2.425 1.362L33.221.839a1.44 1.44 0 0 0-1.404 0l-2.433 1.362L26.957.839a1.44 1.44 0 0 0-1.404 0l-2.433 1.362L20.691.839c-.909-.546-2.173.183-2.14 1.254v15.45H6.752A5.77 5.77 0 0 0 .99 23.307S.624 82.702.958 83.262c.388.694 1.48.941 2.17.553l2.73-1.523 2.73 1.523a1.43 1.43 0 0 0 1.402 0l2.723-1.523 2.723 1.523c.434.245.97.245 1.404 0l2.72-1.523 2.719 1.523c.435.245.97.245 1.404 0l2.713-1.523 2.716 1.523c.434.245.97.245 1.404 0l2.72-1.523 2.715 1.523c.435.245.967.245 1.405 0l2.72-1.523 2.712 1.523c.438.245.97.245 1.404 0l2.716-1.523 2.712 1.523a1.44 1.44 0 0 0 1.958-.55c.334-.56.025-1.088.183-1.659V35.14h8.377a5.187 5.187 0 0 0 5.18-5.18s-.042-27.273-.085-27.41zM46.208 79.387l-2.716 1.523-2.712-1.523a1.43 1.43 0 0 0-1.405 0l-2.72 1.523-2.715-1.523a1.44 1.44 0 0 0-1.405 0l-2.72 1.523-2.719-1.523a1.44 1.44 0 0 0-1.404 0L22.98 80.91l-2.716-1.523a1.44 1.44 0 0 0-1.405 0l-2.723 1.523-2.723-1.523a1.44 1.44 0 0 0-1.404 0L9.286 80.91l-2.73-1.523a1.43 1.43 0 0 0-1.401 0l-1.294.722.004-56.807a2.893 2.893 0 0 1 2.888-2.888H49.2c-.19.5-.309 1.035-.309 1.599v58.092c-.772-.392-1.8-1.286-2.683-.719m5.557-57.378c.036-2.098 3.158-2.102 3.194 0v7.943c0 .833.215 1.61.567 2.306h-3.761zm8.377 10.25a2.31 2.31 0 0 1-2.31-2.307V22.01a4.48 4.48 0 0 0-4.472-4.472H21.42l.004-12.994c.708.392 1.585 1.07 2.396.557l2.432-1.361L28.681 5.1c.434.244.97.244 1.404 0l2.432-1.361L34.946 5.1c.438.244.97.244 1.404 0l2.425-1.361L41.207 5.1c.435.244.967.244 1.405 0L45.04 3.74 47.472 5.1c.431.244.967.244 1.405 0l2.435-1.361L53.744 5.1c.435.244.967.244 1.405 0l2.44-1.365L60.034 5.1a1.43 1.43 0 0 0 1.4 0l1.01-.564v25.416a2.31 2.31 0 0 1-2.306 2.307z"
    />
    <path
      fill="#000"
      d="M42.065 43.106H10.69c-.794 0-1.437.642-1.437 1.436v19.19c0 .794.643 1.438 1.437 1.438h31.375c.794 0 1.437-.644 1.437-1.437v-19.19c0-.794-.643-1.437-1.437-1.437M12.126 52.48h8.618v3.309h-8.618zm11.492 0h17.01v3.309h-17.01zm17.01-2.874h-17.01V45.98h17.01zM20.744 45.98v3.628h-8.618V45.98zm-8.618 12.688h8.618v3.629h-8.618zm11.492 3.629v-3.629h17.01v3.629zM41.806 72.156h-7.62c-1.886.033-1.89 2.842 0 2.874h7.62c1.886-.032 1.89-2.841 0-2.874"
    />
  </svg>
);
