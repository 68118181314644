import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { RULES } from '../rules';
import { FormField, SalesTeamItem } from '@/types';
import { useCountriesAndCities, useRoles } from '@/hooks';
import { API_END_POINTS, LoadingKeys, USER_TYPES } from '..';
import { optionalLabel } from '@/utils';

export const useSalesTeamFields = (selectedMember?: SalesTeamItem) => {
  const { t } = useTranslation();
  const { rolesOptions } = useRoles(`?user_types_id=${USER_TYPES.SALES}`);
  const {
    countries,
    regions,
    isCountriesLoading,
    isRegionsLoading,
    selectedRegion,
    selectedCountry,
    handleRegionChange,
    handleCountryChange,
    cities,
    isCitiesHasMore,
  } = useCountriesAndCities(selectedMember);
  const salesTeamFields = useMemo(
    (): FormField[] => [
      {
        label: t('name'),
        name: 'name',
        type: 'input',
        rules: [RULES.required],
      },
      {
        label: t('role'),
        name: 'role_id',
        type: 'select',
        placeholder: t('selectRole'),
        rules: [RULES.required],
        options: rolesOptions,
      },
      {
        label: t('email'),
        name: 'email',
        type: 'input',
        placeholder: t('enterMemberEmail'),
        rules: [RULES.required, RULES.whiteSpace, RULES.email],
      },
      {
        label: t('phoneNumber'),
        name: 'mobile',
        type: 'phone',
        phoneSelectName: 'country_code',
        rules: [RULES.required, RULES.number, RULES.whiteSpace, RULES.mobile],
      },
      {
        label: t('country'),
        name: 'country_id',
        type: 'select',
        placeholder: t('selectCountry'),
        rules: [RULES.required],
        options: countries.map((item) => ({ label: item.name, value: item.id })),
        selectProps: {
          onSelectChange: handleCountryChange,
        },
        loading: isCountriesLoading,
      },
      {
        label: t('region'),
        name: 'region_id',
        type: 'select',
        placeholder: t('selectRegion'),
        rules: [RULES.required],
        options: regions.map((item) => ({ label: item.name, value: item.id })),
        selectProps: {
          disabled: !selectedCountry,
          onSelectChange: handleRegionChange,
        },
        loading: isRegionsLoading,
      },
      {
        label: t('city'),
        name: 'city_id',
        type: 'infiniteSelect' as const,
        placeholder: t('selectCity'),
        infiniteSelectProps: {
          disabled: !selectedRegion,
          apiEndPoint: API_END_POINTS.cities(selectedRegion),
          loadingKey: LoadingKeys.getCitites,
          initialData: cities.map((item) => ({ name: item.name, id: item.id })),
          initialHasMore: isCitiesHasMore,
          selectParamValue: 'id,name_ar,name_en,created_at',
        },
      },
      {
        label: optionalLabel(t('address')),
        name: 'address',
        type: 'input',
      },
    ],
    [
      rolesOptions,
      countries,
      regions,
      isCountriesLoading,
      isRegionsLoading,
      selectedRegion,
      selectedCountry,
      cities,
      isCitiesHasMore,
    ],
  );

  return {
    salesTeamFields,
  };
};
