export enum LoadingKeys {
  getTheme,
  login,
  resetPassword,
  verifyOtp,
  forgetPassword,
  changePassword,
  getBusinessModels,
  addBusinessModel,
  addCustomBusinessModel,
  setPrevStep,
  setNextStep,
  getAllRoles,
  addTeamMembers,
  getAllTeamMembers,
  updateTeamMember,
  addTeamMember,
  deleteTeamMembers,
  getSalesTeam,
  addSingleSalesTeamMember,
  addSalesTeam,
  AddExternalDistributor,
  getSelectedBusinessModel,
  addProgramDate,
  getCompanySettings,
  getProgramDate,
  addOrEditCategory,
  deleteCategory,
  deleteSubcategory,
  deleteProduct,
  deleteSKU,
  addOrEditSubcategory,
  addOrEditProduct,
  addOrEditSKU,
  addOrEditTeamMember,
  deleteTeamMember,
  addOrEditMarketingGroup,
  deleteMarketingGroup,
  assignSKUsToMarketingGroup,
  deleteListItem,
  editListItem,
  addTopic,
  getAllCategories,
  getAllCategoriesLite,
  getAllSubcategories,
  deleteBrand,
  addOrEditBrand,
  getAllSubcategoriesLite,
  getAllBrands,
  getAllSKUs,
  getAllBrandsLite,
  getAllProducts,
  deleteItem,
  getAllSKUsLite,
  getAllItems,
  addOrEditItem,
  getCategoryById,
  getSubcategoryById,
  getBrandById,
  getSKUById,
  getAllMarketingGroups,
  getAllItemsLite,
  assignItemToMarketingGroup,
  getAssignedItemsToMarketingGroup,
  unAssignItemFromMarketingGroup,
  getAllTopics,
  addOrEditTopic,
  getAllDistributors,
  createOrUpdateDistributor,
  deleteDistributor,
  getDistributorById,
  getAllSalesTeamMembers,
  addOrEditSalesTeamMember,
  deleteSalesTeamMember,
  updateCompanySettings,
  getCompanySettingsData,
  deleteTopic,
  getTopicDetails,
  createOrUpdateTopic,
  getAllArticles,
  getArticleDetails,
  deleteArticle,
  createOrUpdateArticle,
  uploadFile,
  getMarketingGroupDetails,
  getItemById,
  getDashboardData,
  getPointsFormula,
  updatePointsFormula,
  updateItemPointFormula,
  updateProfile,
  deleteAccount,
  getProfileData,
  getAllTiers,
  deleteTier,
  addOrEditTier,
  getTierById,
  getAllLogs,
  getPointFormulaOptions,
  importData,
  deleteCustomer,
  getAllCustomers,
  getCustomerDetails,
  createOrUpdateCustomer,
  getAllTickets,
  deleteTicket,
  createOrUpdateTicket,
  getTicketDetails,
  getAllTicketTypes,
  getTicketTypeDetails,
  createOrUpdateTicketType,
  deleteTicketType,
  getAllCustomersLite,
  getAllInvoices,
  deleteInvoice,
  getInvoiceById,
  createOrUpdateInvoice,
  getAllTicketComments,
  addTicketComment,
  getAllMarketingGroupsLite,
  calculateItemPoints,
  storeOrder,
  getAllOrders,
  getOrderDetails,
  getCompanyDataByHostName,
  getEntities,
  getAllEarnAndBurn,
  getAllPartners,
  deletePartner,
  addOrEditPartner,
  getRolePermissions,
  updateRolePermissions,
  getAllRedeemRequests,
  changeRedeemRequestStatus,
  addOrEditRole,
  getAllBusinessModels,
  createOrUpdateBusinessModel,
  deleteBusinessModel,
  getAllCompanies,
  createOrUpdateCompany,
  deleteCompany,
  changeOrderStatus,
  getAllPartnerCategoriesLite,
  getAllPartnerCategories,
  CreateOrUpdatePartnerCategory,
  deletePartnerCategory,
  createOrUpdateOrder,
  getCustomerRedemption,
  redeemCard,
  changeCompanySettings,
  getAllCompaniesLite,
  downloadTemplate,
  getAllPartnersLite,
  getAllRedeemHistory,
  createOrUpdateRedeemHistory,
  getCountries,
  getCitites,
  getRegions,
}
