import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { PARAM_RULES, RULES } from '../rules';
import { FormField, ICompanyItem } from '@/types';
import { STATUS_OPTIONS } from '../sales-constants';
import { useCountriesAndCities } from '@/hooks';
import { API_END_POINTS } from '../api-end-points';
import { LoadingKeys } from '../loading-keys';
import { optionalLabel } from '@/utils';

export const useCompanyFields = (selectedCompany: ICompanyItem) => {
  const { t } = useTranslation();
  const {
    countries,
    regions,
    isCountriesLoading,
    isRegionsLoading,
    selectedRegion,
    selectedCountry,
    handleRegionChange,
    handleCountryChange,
    cities,
    isCitiesHasMore,
  } = useCountriesAndCities(selectedCompany);
  const companyFields = useMemo(
    (): FormField[] => [
      {
        label: t('name'),
        name: 'name',
        type: 'input',
        placeholder: t('enterCompanyName'),
        rules: [RULES.required],
      },
      {
        label: t('subDomain'),
        name: 'sub_domain',
        type: 'input',
        rules: [RULES.required],
      },
      {
        label: t('email'),
        name: 'email',
        type: 'input',
        placeholder: t('enterYourEmail'),
        rules: [RULES.required, RULES.email],
      },

      ...(!selectedCompany
        ? [
            {
              label: t('password'),
              name: 'password',
              type: 'password' as const,
              placeholder: t('createAPassword'),
              rules: [RULES.required, PARAM_RULES.min(8), RULES.whiteSpace],
            },
          ]
        : []),

      {
        label: t('phoneNumber'),
        name: 'mobile',
        type: 'phone',
        phoneSelectName: 'country_code',
        rules: [RULES.required, RULES.number, RULES.whiteSpace, RULES.mobile],
      },
      {
        label: t('country'),
        name: 'country_id',
        type: 'select',
        placeholder: t('selectCountry'),
        rules: [RULES.required],
        options: countries.map((item) => ({ label: item.name, value: item.id })),
        selectProps: {
          onSelectChange: handleCountryChange,
        },
        loading: isCountriesLoading,
      },
      {
        label: t('region'),
        name: 'region_id',
        type: 'select',
        placeholder: t('selectRegion'),
        rules: [RULES.required],
        options: regions.map((item) => ({ label: item.name, value: item.id })),
        selectProps: {
          disabled: !selectedCountry,
          onSelectChange: handleRegionChange,
        },
        loading: isRegionsLoading,
      },
      {
        label: t('city'),
        name: 'city_id',
        type: 'infiniteSelect' as const,
        placeholder: t('selectCity'),
        infiniteSelectProps: {
          disabled: !selectedRegion,
          apiEndPoint: API_END_POINTS.cities(selectedRegion),
          loadingKey: LoadingKeys.getCitites,
          initialData: cities.map((item) => ({ name: item.name, id: item.id })),
          initialHasMore: isCitiesHasMore,
          selectParamValue: 'id,name_ar,name_en,created_at',
        },
      },
      {
        label: optionalLabel(t('address')),
        name: 'address',
        type: 'input',
      },
      {
        label: t('registrationNumber'),
        name: 'registration_number',
        type: 'input',
        rules: [RULES.required, RULES.number, RULES.integers, RULES.whiteSpace],
      },
      {
        label: t('representativeFullName'),
        name: 'representative_full_name',
        type: 'input',
        rules: [RULES.required],
      },
      {
        label: t('logo'),
        name: 'logo',
        type: 'uploadImage',
        uploadImageProps: {
          maxImagesCount: 1,
          maxSizeInMB: 1,
        },
      },
    ],
    [
      selectedCompany,
      countries,
      regions,
      isCountriesLoading,
      isRegionsLoading,
      selectedRegion,
      selectedCountry,
      cities,
      isCitiesHasMore,
    ],
  );
  const companySearchFields = useMemo(
    (): FormField[] => [
      {
        label: t('status'),
        name: 'status',
        type: 'select',
        placeholder: t('selectStatus'),
        options: STATUS_OPTIONS,
      },
    ],
    [],
  );
  return {
    companyFields,
    companySearchFields,
  };
};
