import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { RULES } from '../rules';
import { DistributorItem, FormField } from '@/types';
import { useCountriesAndCities, useRoles } from '@/hooks';
import { API_END_POINTS, LoadingKeys, USER_TYPES } from '..';
import { optionalLabel } from '@/utils';

export const useDistributorsFields = (selectedDistributor?: DistributorItem) => {
  const { t } = useTranslation();
  const { rolesOptions } = useRoles(`?user_types_id=${USER_TYPES.DISTRIBUTOR}`);
  const {
    countries,
    regions,
    isCountriesLoading,
    isRegionsLoading,
    selectedRegion,
    selectedCountry,
    handleRegionChange,
    handleCountryChange,
    cities,
    isCitiesHasMore,
  } = useCountriesAndCities(selectedDistributor);
  const distributorsFields = useMemo(
    (): FormField[] => [
      {
        label: t('name'),
        name: 'name',
        type: 'input',
        rules: [RULES.required],
      },
      {
        label: t('email'),
        name: 'email',
        type: 'input',
        rules: [RULES.required, RULES.email],
      },
      {
        label: t('role'),
        name: 'role_id',
        type: 'select',
        placeholder: t('selectRole'),
        rules: [RULES.required],
        options: rolesOptions,
      },
      // ...(!distributorId
      //   ? [
      //       {
      //         label: t('password'),
      //         name: 'password',
      //         type: 'password' as const,
      //         placeholder: t('createAPassword'),
      //         rules: [RULES.required, PARAM_RULES.min(8), RULES.whiteSpace],
      //       },
      //     ]
      //   : []),

      {
        label: t('phoneNumber'),
        name: 'mobile',
        type: 'phone',
        phoneSelectName: 'country_code',
        rules: [RULES.required, RULES.number, RULES.whiteSpace, RULES.mobile],
      },
      {
        label: t('country'),
        name: 'country_id',
        type: 'select',
        placeholder: t('selectCountry'),
        rules: [RULES.required],
        options: countries.map((item) => ({ label: item.name, value: item.id })),
        selectProps: {
          onSelectChange: handleCountryChange,
        },
        loading: isCountriesLoading,
      },
      {
        label: t('region'),
        name: 'region_id',
        type: 'select',
        placeholder: t('selectRegion'),
        rules: [RULES.required],
        options: regions.map((item) => ({ label: item.name, value: item.id })),
        selectProps: {
          disabled: !selectedCountry,
          onSelectChange: handleRegionChange,
        },
        loading: isRegionsLoading,
      },
      {
        label: t('city'),
        name: 'city_id',
        type: 'infiniteSelect' as const,
        placeholder: t('selectCity'),
        infiniteSelectProps: {
          disabled: !selectedRegion,
          apiEndPoint: API_END_POINTS.cities(selectedRegion),
          loadingKey: LoadingKeys.getCitites,
          initialData: cities.map((item) => ({ name: item.name, id: item.id })),
          initialHasMore: isCitiesHasMore,
          selectParamValue: 'id,name_ar,name_en,created_at',
        },
      },
      {
        label: optionalLabel(t('address')),
        name: 'address',
        type: 'input',
      },
      {
        label: t('registrationNumber'),
        name: 'registration_number',
        type: 'input',
        rules: [RULES.required, RULES.number, RULES.integers, RULES.whiteSpace],
      },
      {
        label: t('representativeFullName'),
        name: 'representative_full_name',
        type: 'input',
        rules: [RULES.required],
      },
      {
        label: t('logo'),
        name: 'logo',
        type: 'uploadImage',
        uploadImageProps: {
          maxImagesCount: 1,
          maxSizeInMB: 1,
        },
      },
    ],
    [
      rolesOptions,
      countries,
      regions,
      isCountriesLoading,
      isRegionsLoading,
      selectedRegion,
      selectedCountry,
      cities,
      isCitiesHasMore,
    ],
  );

  return {
    distributorsFields,
  };
};
