import { LoadingKeys } from '@/constants';
import { useCommonStore, useLoadingStore } from '@/stores';
import { useEffect, useState } from 'react';
import { StringOrNumber } from '@/types';
import { DefaultOptionType } from 'antd/es/select';
import { FormInstance } from 'antd';

export const useCountriesAndCities = (selectedRecord?: { country_id: number; region_id: number; city_id: number }) => {
  const { getLoading } = useLoadingStore((state) => state);
  const [selectedCountry, setSelectedCountry] = useState<StringOrNumber>();
  const [selectedRegion, setSelectedRegion] = useState<StringOrNumber>();

  const { countries, regions, getCountries, getCitiesByRegionId, isCitiesHasMore, cities, getRegionsByCountryId } =
    useCommonStore((state) => state);
  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (selectedRecord) {
      const { country_id, region_id } = selectedRecord;
      if (country_id) {
        setSelectedCountry(country_id);
        getRegionsByCountryId(country_id);
      }
      if (region_id) {
        setSelectedRegion(region_id);
        getCitiesByRegionId(region_id);
      }
    } else {
      setSelectedCountry(undefined);
      setSelectedRegion(undefined);
    }
  }, [selectedRecord]);

  const handleCountryChange = (value: StringOrNumber, option: DefaultOptionType, form: FormInstance) => {
    getRegionsByCountryId(value);
    setSelectedCountry(value);
    form.setFieldValue('region_id', undefined);
    form.setFieldValue('city_id', undefined);
  };

  const handleRegionChange = (value: StringOrNumber, option: DefaultOptionType, form: FormInstance) => {
    setSelectedRegion(value);
    getCitiesByRegionId(value);
    form.setFieldValue('city_id', undefined);
  };

  return {
    countries,
    regions,
    cities: cities,
    isCountriesLoading: getLoading(LoadingKeys.getCountries),
    isCitiesLoading: getLoading(LoadingKeys.getCitites),
    isRegionsLoading: getLoading(LoadingKeys.getRegions),
    getCountries,
    getRegionsByCountryId,
    setSelectedCountry,
    setSelectedRegion,
    selectedRegion,
    selectedCountry,
    handleRegionChange,
    handleCountryChange,
    isCitiesHasMore: isCitiesHasMore,
  };
};
